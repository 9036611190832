.swiper-button-next:after,
.swiper-button-prev::after {
  color: rgb(9, 78, 24);
  font-size: 1.85rem;
  font-weight: 600 !important;
}

.swiper-wrapper {
    transition-timing-function: linear;
  }
