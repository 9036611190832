input:checked ~ .dot {
    transform: translateX(100%);
  }
  
  input:checked ~ .block {
    background-color: #b5cf8f;
  }
  
  .slide-in {
    animation: slide-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .slide-out {
    animation: slide-out 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  

  .slide-out-small {
    animation: slide-out-small 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .slide-in-small {
    animation: slide-in-small 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }


  
  @keyframes slide-out-small {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes slide-in-small {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  